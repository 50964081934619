// This file holds the base environment configuration. The ng build commands:
//   `ng build --configuration=local`
//   `ng build --configuration=development`
//   `ng build --configuration=uat`
//   `ng build --prod`
// replace the `environment.ts` the respective files:
//   `environment.local.ts`
//   `environment.dev.ts`
//   `environment.uat.ts`
//   `environment.prod.ts`
//
// The list of file replacements are found in `angular.json`,
// and the build commands are found in `package.json`.

export const commonEnvironment = {
  production: false,
  timeout: 10000000000000, // 7200, ( in seconds)
  internalApi: {
    facDataVersions: '/facdataversions',
    facPropertySubmission: '/facpropertysubmission',
    facReinsurer: '/facreinsurer',
    facbasisofacceptance: '/facbasisofacceptance',
    faccurrency: '/faccurrency',
    facnamedinsured: '/facnamedinsured',
    facreinsuranceconditions: '/facreinsuranceconditions',
    factermsandconditions: '/factermsandconditions',
    factypeofinsurance: '/factypeofinsurance',
    facBinder: '/facbinder',
    facData: '/facdata',
    sendEmail: '/sendEmail',
    facMailingGroups: '/facmailinggroups',
    facUserRelationship: '/fac_user_relationship',
    facUserType: '/fac_user_type',
    base: '/api',
    // base: 'https://dev.mobile.sage.lockton.com/api',// Use if having authentication issues locally
    users: '/users',
    slipTemplates: '/sliptemplates',
    userRoles: '/userroles',
    roles: '/roles',
    sageFunctionality: '/sagefunctionality',
    permissions: '/permissions',
    market: '/market',
    log: '/log',
    analyzereAuth: '/analyzere/auth',
    portfolioThumbnails: '/imagethumbnails',
    facThumbnails: '/imagethumbnails/facthumbnail',
    layerData: '/layerdata',
    marketLayer: '/marketlayer',
    carrierThumbnails: '/imagecarriers',
    facFiles: '/facFiles',
    deleteFacDocument: '/deleteFacDocument',
    program: {
      base: '/programs',
      tailMetrics: '/tail_metrics',
    },
    captureExportDetails: '/captureExportDetails',
    carrier: '/carriers',
    accountInitiation: '/accountInitiation',
    study: '/study',
    year: '/carrieryears',
    metricSettings: '/studymetricsetting',
    reinsurers: '/reinsurerprogramfactors',
    grossLossGraph: '/grossloss/{grossPortfolioID}/lob/{id}',
    towerPreferences: '/towerpreferences/{id}',
    risk: '/risks',
    riskSection: '/risk_sections',
    riskReinsurer: '/risk_reinsurers',
    riskSubjectivity: '/risk_subjectivities',
    riskExclusion: '/risk_exclusions',
    riskReinstatement: '/risk_reinstatements',
    riskInuringLayer: '/risk_inuring_layers',
    riskReinsurerSubjectivities: '/risk_reinsurer_subjectivities',
    riskAssignedLines: '/risk_assignedLines',
    managementInformation: '/management-information',
    quoteManagementInformation: '/quote-management-information',
    signature: '/signature',
    revenue: '/revenue',
    signatureSalesforceClients: '/signature/salesforceClients',
    agencies: '/agencies',
    agencyDetails: '/agencyDetails',
    agencyBridge: '/agencyBridgeSAGEOT',
    agencyDetailsSage: '/agencyDetailsSAGE',
    bureaus: '/bureaus',
    ot_risk: '/ot_risk',
    ot_risk_exportlipTemplate: '/ot_risk/DownloadAndReplaceWordTemplates',
    ot_risk_confirm: '/ot_risk/confirmation',
    ot_risk_sections: '/ot_risk/risksections',
    ot_risk_details: '/ot_risk/riskdetails',
    ot_risk_createquote: '/ot_risk/createquote',
    ot_risk_sections_with_markets: '/ot_risk/risksectionswithmarkets',
    ot_risk_confirm_risk_details: '/ot_risk/confirmRiskDetails',
    processLogs: '/processlogs',
    sharedLimit: '/shared_limits',
    sharedLimitMembers: '/shared_limit_members',
    compareView: '/compareviews',
    programRiskCode: '/programriskcode',
    reinsurerPriorYear: '/reinsurer_prior_year_view',
    perils: '/perils',
    territory: '/territory',
    whitespace: '/whitespace',
    whitespace_save_template: '/whitespace/risks/saveTemplate/',
    azureb2c: '/b2c-config',
    whitespace_risks: '/whitespace/risks',
    evm: '/evm/',
    signSets: '/whitespace/risks/{id}/signSets',
    riskCodes: '/lloydsriskcodes',
  },
  marketApi: {
    base: 'https://lockton-re-site-dev.azurewebsites.net/api',
    authenticate: '/user/authenticate',
    program: '/programs?PartyReference={carrierID}',
    quotations: '/programs/{id}/quotations?PartyReference={carrierID}',
  },
  documentApi: {
    base: 'https://lockton-re-docs-dev.azurewebsites.net/api',
    files: '/files',
    documents: '/documents?PartyReference={carrierID}',
    deleteFacDocuments:
      '/documents/delete?PartyReference={carrierID}&fac-name={facName}&fac-id={facID}&DocumentClasses=All',
    facDocuments:
      '/documents?PartyReference={carrierID}&fac-name={facName}&fac-id={facID}',
  },
  salesforceURI: {
    base: 'https://locktonre--uatfull.sandbox.lightning.force.com',
  },
  api: {
    base: 'https://lockton-api.analyzere.net',
    portfolios: '/portfolios',
    layers: '/layers',
    batchLayers: '/layers/?ids=',
    portfolioViewPost: '/portfolio_views',
    layerViewsPost: '/layer_views',
    files: '/files',
    eventCatalogs: '/event_catalogs',
    lossFilters: '/loss_filters',
    lossSets: '/loss_sets',
    analysisProfiles: '/analysis_profiles',
    simulations: '/simulations',
    exchangeRateCurrencies: '/exchange_rate_tables',
    metrics: {
      layersView: {
        base: '/layer_views',
        url:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/{returnPeriod1},{returnPeriod2},{returnPeriod3},{returnPeriod4},{returnPeriod5}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true',
        depositPremium:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=Premium&aggregation_method=AEP',
        expectedCededPremiumBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=ReinstatementPremium&aggregation_method=AEP',
        expectedCededLossBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=NetLoss&aggregation_method=AEP',
        expectedCededExpensesBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
        expectedCededMarginBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=true&perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP',
        aepBase:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/{yearParam},0.004?apply_participation=true&perspective=NetLoss&aggregation_method={methodParam}',
        oepWindowVarBase:
          // tslint:disable-next-line: max-line-length
          '/window_metrics/0.100_0.250?apply_participation=true&perspective=NetLoss&aggregation_method=OEP',
        aepWindowVarBase:
          // tslint:disable-next-line: max-line-length
          '/window_metrics/0.100_0.250?apply_participation=true&perspective=NetLoss&aggregation_method=AEP',
        probablity: '/exceedance_probabilities/{param}?perspective=NetLoss',
        expensePremium:
          '/tail_metrics/1?loss_perspective=LossNetOfAggregateTerms[Premium]',
        expensePremiumWithoutLoss:
          '/tail_metrics/1?perspective=LossNetOfAggregateTerms[Premium]',
        expectedCededExpensesBaseNoParticipation:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=false&perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
        expectedCededLossBaseNoParticipation:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/1?apply_participation=false&perspective=NetLoss&aggregation_method=AEP',
        explore:
          '/tail_metrics/{returnPeriods}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true{lossFilter}',
        cededYearValue:
          '/tail_metrics/{yearParam}?perspective=NetLoss&aggregation_method=AEP&apply_participation=false',
      },
      portfolioView: {
        base: '/portfolio_views',
        url:
          // tslint:disable-next-line: max-line-length
          '/tail_metrics/{returnPeriod1},{returnPeriod2},{returnPeriod3},{returnPeriod4},{returnPeriod5}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true{lossFilter}',
        detail: {
          lossNetAggregateTermAEP:
            '/tail_metrics/1?perspective=NetLoss&aggregation_method=AEP',
          lossNetPremiumReinstatementAggregateTermAEP:
            '/tail_metrics/1?perspective=NetLoss,ReinstatementPremium&aggregation_method=AEP',
          lossNetAggreateTermsPremiumReinstatementAEP:
            '/tail_metrics/1?perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP',
          reinstatementPremiumAEP:
            '/tail_metrics/1?perspective=ReinstatementPremium&aggregation_method=AEP',
          lossNetOfAggregateTermsAEPReportingPeriod:
            '/tail_metrics/1?perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
          lossNetAggregateTermAEPParticipation:
            '/tail_metrics/1?perspective=NetLoss&aggregation_method=AEP&apply_participation=true',
          lossNetAggreateTermsPremiumReinstatementAEPParticipation:
            '/tail_metrics/1?perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP&apply_participation=true',
          explore:
            '/tail_metrics/{returnPeriods}?perspective={perspective}&aggregation_method={aggregation}&apply_participation=true{lossFilter}',
          grossTableTotalExpensePremium:
            '/tail_metrics/1?loss_perspective=LossNetOfAggregateTerms[Premium]',
          grossTableTotalExpensePremiumWithoutLoss:
            '/tail_metrics/1?perspective=LossNetOfAggregateTerms[Premium]',
          premiumAEP:
            '/tail_metrics/1?perspective=Premium&aggregation_method=AEP',
        },
        volatility: {
          lossNetAggregateTermAEP:
            '/tail_metrics/1,{year1},{year2}?perspective=NetLoss&aggregation_method=AEP',
          netStandardDeviation:
            '/tail_metrics/1,0.100?perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP&apply_participation=true',
          lossNetOfAggregateTermsAEPReportingPeriod:
            '/tail_metrics/1,0.100?perspective=FixedExpense,ProportionalExpense,ProfitCommission&aggregation_method=AEP',
        },
        capital: {
          lossNetAggregateTermAEP:
            '/tail_metrics/0.004?perspective=NetLoss&aggregation_method=AEP&filter=cat',
          lossNetAggregateTermOEP:
            '/tail_metrics/0.004?perspective=NetLoss&aggregation_method=OEP&filter=cat',
        },
        efficiency: {
          volatility:
            '/tail_metrics/1?perspective=Premium,ReinstatementPremium,NetLoss,FixedExpense,ProportionalExpense,ProfitCommission,NoClaimsBonus&aggregation_method=AEP&apply_participation=true',
        },
        misc: {
          programAttachOcc:
            '/exceedance_probabilities/0?perspective=NetLoss&aggregatation_method=OEP',
          programAttachAgg:
            '/exceedance_probabilities/0?perspective=NetLoss&aggregatation_method=AEP',
          probabilityMetric:
            '/exceedance_probabilities/{threshold}?perspective={perspective}&aggregatation_method={aggregation}{lossFilter}',
        },
        tail: {
          url: '/tail_metrics/{year}?perspective={perspective}&aggregation_method={aggregation}{lossFilter}',
        },
      },
    },
  },
  form: {
    fieldInterfaces: [
      {
        name: 'misc',
        fields: [
          [
            {
              id: 'reinsurers',
              type: 'select',
              label: 'Reinsurers',
              readonly: 'false',
              options: [],
              endpoint: 'facReinsurer|reinsurerName',
              validations: [],
              value: '',
            },
          ],
        ],
      },
      {
        name: 'misc',
        fields: [
          [
            {
              id: 'userType',
              type: 'select',
              label: 'Broker',
              readonly: 'false',
              options: [],
              endpoint: 'facUserType|userType',
              validations: [],
              value: '',
            },
          ],
        ],
      },
      {
        name: 'facLanding',
        fields: [
          [
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              casLabel: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsured',
              type: 'select',
              label: 'Reinsured',
              casLabel: 'Ceding Company',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Required',
                },
              ],
              value: '',
              autocomplete: true,
            },
            {
              id: 'reinsuredUnderwriter',
              type: 'select',
              label: 'Reinsured Underwriter',
              casLabel: 'Cedant Underwriter',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              autocomplete: true,
            },
          ],
        ],
      },
      {
        name: 'submission-1',
        type: 'property',
        fields: [
          [
            {
              id: 'reinsured',
              type: 'select',
              label: 'Reinsured',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Required',
                },
              ],
              value: '',
              autocomplete: true,
            },
          ],
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'select',
              label: 'Reinsured Underwriter',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              autocomplete: true,
            },
          ],
          [
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'websiteURL',
              type: 'textarea',
              label: 'Website',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Website Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Insured Street Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'insuredValues',
              type: 'input',
              label: 'Total Insured Values',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Total Insured Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              defaultValue: 'USD',
              readonly: 'false',
              validations: [],
              options: [],
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'policyLimit',
              type: 'input',
              label: 'Policy Limit',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Limit Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'typeOfInsurance',
              type: 'select',
              label: 'Perils Insured',
              readonly: 'false',
              options: [
                'All Risks of Physical Loss or Damage',
                'All Risks',
                'Difference in Conditions',
                'Named Perils',
                'Only',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'including',
              type: 'multiselect',
              label: 'Including',
              readonly: 'false',
              hideSelector: false,
              bullets: true,
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'excluding',
              type: 'multiselect',
              label: 'Excluding',
              hideSelector: false,
              bullets: true,
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'only',
              type: 'select',
              label: 'Only',
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'basisOfAcceptance',
              type: 'select',
              label: 'Basis of Acceptance',
              readonly: 'false',
              options: [],
              endpoint: 'facbasisofacceptance|basisOfAcceptance',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis of Acceptance Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'companyRetentionNetOrTreaty',
              type: 'textarea',
              label: 'Company Retention (N/T)',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Company Retention (N/T) Treaty Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'quoteNeededBy',
              type: 'date',
              label: 'Quote Needed By Date',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'brokerageCommissionSub',
              type: 'input',
              label: 'Brokerage Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value between 55%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '10.00%',
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceConditions',
              type: 'multiselect',
              label: 'Reinsurance Conditions',
              value: '',
              readonly: 'false',
              hideSelector: false,
              options: [],
              validations: [],
              endpoint: 'facreinsuranceconditions|reinsuranceConditions',
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'underlyingInsurance',
              type: 'textarea',
              label: 'Underlying Insurance',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Underlying Insurance Required',
                },
              ],
              value: '',
              defaultValue: 'N/A',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cancellationClause',
              type: 'textarea',
              label: 'Cancellation Clause',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Cancellation Clause Required',
                },
              ],
              value: '',
              defaultValue: 'As per original',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'claimsNotification',
              type: 'textarea',
              label: 'Claims Notification',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Claims Nofitication Required',
                },
              ],
              value: '',
              defaultValue: 'As per original',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              defaultValue: 'Lockton Re Master Certificate',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'Terms and Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Terms and Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'descriptionOfOperations',
              type: 'textarea',
              label: 'Description of Operations',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Description Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'lossHistory',
              type: 'textarea',
              label: 'Loss History',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Loss History Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
        ],
      },
      {
        name: 'submission-1',
        type: 'casualty',
        fields: [
          [
            {
              id: 'reinsured',
              type: 'select',
              label: 'Ceding Company',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Required',
                },
              ],
              value: '',
              autocomplete: true,
            },
          ],
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'select',
              label: 'Reinsured Underwriter',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              autocomplete: true,
            },
          ],
          [
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'websiteURL',
              type: 'textarea',
              label: 'Website',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Website Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'descriptionOfOperations',
              type: 'textarea',
              label: 'Description of Operations',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Description of Operations Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'coverageReinsured',
              type: 'select',
              label: 'Coverage Reinsured',
              readonly: 'false',
              options: [
                'Auto Liability',
                'General Liability',
                'Umbrella / Excess Liability',
                "Workers' Compensation",
                'Professional Liability',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Name Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'coverageBasis',
              type: 'select',
              label: 'Coverage Basis',
              readonly: 'false',
              options: ['Occurrence', 'Claims Made'],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'policyRetroDate',
              type: 'date',
              label: 'Policy Retro Date',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Retro Date Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: false,
            },
            {
              id: 'ReinsuranceRetroDate',
              type: 'date',
              label: 'Reinsurance Retro Date',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Retro Date Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyLimit',
              type: 'input',
              label: 'Company Policy Limit',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Limit Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'generalAgg',
              type: 'input',
              label: 'General Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'General Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
            {
              id: 'otherAgg',
              type: 'input',
              label: 'Products and Completed Operations Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message:
                    'Products and Completed Operations Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
          ],
          [
            {
              id: 'programStructure',
              type: 'select',
              label: 'Program Structure',
              readonly: 'false',
              options: [
                'Guaranteed Cost',
                'Self-Insured Retention',
                'Deductible',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Program Structure Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'retentionAmount',
              type: 'input',
              label: 'Retention Amount',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Retention Required',
                },
              ],
              value: '',
              showCurrency: true,
              defaultValue: '0',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPremium',
              type: 'input',
              label: 'Annual Policy Premium',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Annual Policy Premium Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'premiumComments',
              type: 'textarea',
              label: 'Premium Comments',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Premium Comments Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              defaultValue: 'USD',
              readonly: 'false',
              options: [],
              validations: [],
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'reinsuranceCoverage',
              type: 'textarea',
              label: 'Reinsurance Coverage',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Coverage Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: 'Follow Form Company Policy',
            },
          ],
          [
            {
              id: 'minimumAndDeposit',
              type: 'textarea',
              label: 'Minimum and Deposit',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Minimum and Deposit Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '100% Minimum & Deposit',
            },
          ],
          [
            {
              id: 'brokerageCommissionSub',
              type: 'input',
              label: 'Brokerage Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '10.00%',
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 40%',
                },
                {
                  name: 'min',
                  validator: 0,
                  message: 'Please enter value less than or equal to 40%',
                },
                {
                  name: 'max',
                  validator: 40,
                  message: 'Please enter value less than or equal to 40%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'basisOfAcceptance',
              type: 'select',
              label: 'Basis of Acceptance',
              readonly: 'false',
              options: [],
              endpoint: 'facbasisofacceptance|basisOfAcceptance',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis of Acceptance Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'allocatedLossAdjustmentExpense',
              type: 'select',
              label: 'ALAE',
              options: [
                'Outside Limit / Outside Certificate',
                'Inside Limit / Inside Certificate',
                'Capped',
                'Excluding Defense',
              ],
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'ALAE Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: 'Lockton Re Master Certificate',
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'General Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'General Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
        ],
      },
      {
        name: 'binder-1',
        type: 'property',
        fields: [
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'textarea',
              label: 'Reinsured Underwriter',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: true,
            },
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Insured Street Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'insuredValues',
              type: 'input',
              label: 'Total Insured Values',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Total Insured Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              readonly: 'false',
              options: [],
              validations: [],
              defaultValue: 'USD',
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'reinsured',
              type: 'textarea',
              label: 'Cedent Company',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Company Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyLimit',
              type: 'input',
              label: 'Company Policy Limit',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Limit Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
          ],
          [
            {
              id: 'companyRetentionNetOrTreaty',
              type: 'textarea',
              label: 'Cedent Company Retention',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Retention Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'typeOfInsurance',
              type: 'select',
              label: 'Perils Insured',
              readonly: 'false',
              options: [
                'All Risks of Physical Loss or Damage',
                'All Risks',
                'Difference in Conditions',
                'Named Perils',
                'Only',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'including',
              type: 'multiselect',
              label: 'Including',
              readonly: 'false',
              hideSelector: false,
              bullets: true,
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'excluding',
              type: 'multiselect',
              label: 'Excluding',
              hideSelector: false,
              bullets: true,
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'only',
              type: 'select',
              label: 'Only',
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'brokerageCommissionSub',
              type: 'input',
              label: 'Brokerage Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value between 55%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '10.00%',
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              defaultValue: 'Lockton Re Master Certificate',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceConditions',
              type: 'multiselect',
              label: 'Reinsurance Conditions',
              value: '',
              readonly: 'false',
              hideSelector: false,
              options: [],
              endpoint: 'facreinsuranceconditions|reinsuranceConditions',
              validations: [],
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'additionalReinsuranceConditions',
              type: 'textarea',
              label: 'Additional Reinsurance Conditions',
              inputType: 'text',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'Terms and Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Terms and Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'descriptionOfOperations',
              type: 'textarea',
              label: 'Description of Operations',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Description Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'lossHistory',
              type: 'textarea',
              label: 'Loss History',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Loss History Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
        ],
      },
      {
        name: 'binder-1',
        type: 'casualty',
        fields: [
          [
            {
              id: 'reinsured',
              type: 'textarea',
              label: 'Reinsured',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'textarea',
              label: 'Reinsured Underwriter',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: true,
            },
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'coverageReinsured',
              type: 'select',
              label: 'Coverage Reinsured',
              readonly: 'false',
              options: [
                'Auto Liability',
                'General Liability',
                'Umbrella / Excess Liability',
                "Workers' Compensation",
                'Professional Liability',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Name Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'policyLimit',
              type: 'input',
              label: 'Company Policy Limit',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Limit Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'generalAgg',
              type: 'input',
              label: 'General Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'General Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
            {
              id: 'otherAgg',
              type: 'input',
              label: 'Products and Completed Operations Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message:
                    'Products and Completed Operations Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
          ],
          [
            {
              id: 'programStructure',
              type: 'select',
              label: 'Program Structure',
              readonly: 'false',
              options: [
                'Guaranteed Cost',
                'Self-Insured Retention',
                'Deductible',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Program Structure Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'retentionAmount',
              type: 'input',
              label: 'Retention Amount',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Retention Required',
                },
              ],
              value: '',
              showCurrency: true,
              defaultValue: '0',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'basisOfAcceptance',
              type: 'textarea',
              label: 'Basis of Acceptance',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis of Acceptance Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              readonly: 'false',
              options: [],
              validations: [],
              defaultValue: 'USD',
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'brokerageCommissionSub',
              type: 'input',
              label: 'Brokerage Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value between 55%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '10.00%',
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceConditions',
              type: 'multiselect',
              label: 'Reinsurance Conditions',
              value: '',
              readonly: 'false',
              hideSelector: false,
              options: [],
              endpoint: 'facreinsuranceconditions|reinsuranceConditions',
              validations: [],
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'additionalReinsuranceConditions',
              type: 'textarea',
              label: 'Additional Reinsurance Conditions',
              inputType: 'text',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'otherFacultative',
              type: 'textarea',
              label: 'Other Facultative',
              inputType: 'text',
              readonly: 'true',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'minimumAndDeposit',
              type: 'textarea',
              label: 'Minimum and Deposit',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Minimum and Deposit Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '100% Minimum & Deposit',
            },
          ],
          [
            {
              id: 'rateAdjustability',
              type: 'textarea',
              label: 'Rate Adjustability',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Rate Adjustability Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: 'Reinsurance Premium is Flat',
            },
          ],
          [
            {
              id: 'claimsNotification',
              type: 'textarea',
              label: 'Claims Notification',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Claims Nofitication Required',
                },
              ],
              value: '',
              defaultValue: 'As per General Conditions',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'premiumTypecas',
              type: 'textarea',
              label: 'Premium Type',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Claims Nofitication Required',
                },
              ],
              value: '',
              defaultValue: 'Gross Premium',
              hiddenToggle: false,
              hiddenToggleValue: true,
            },
          ],
          [
            {
              id: 'reinsurerParticipation',
              type: 'textarea',
              label: 'Reinsurer Bound Participation',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Participation Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              defaultValue: 'Lockton Re Master Certificate',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'General Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'General Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'paymentDue',
              type: 'textarea',
              label: 'Payment Due',
              readonly: 'false',
              inputType: 'text',
              validations: [],
              value: '',
              defaultValue:
                '60 days from the end of the month in which the policy is effective.',
            },
          ],
        ],
      },
      {
        name: 'certificate-1',
        type: 'property',
        fields: [
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'textarea',
              label: 'Reinsured Underwriter',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: true,
            },
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Insured Street Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'insuredValues',
              type: 'input',
              label: 'Total Insured Values',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Total Insured Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              readonly: 'false',
              options: [],
              validations: [],
              defaultValue: 'USD',
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'reinsured',
              type: 'textarea',
              label: 'Cedent Company',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Company Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'companyRetentionNetOrTreaty',
              type: 'textarea',
              label: 'Cedent Company Retention',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Retention Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'typeOfInsurance',
              type: 'select',
              label: 'Perils Insured',
              readonly: 'false',
              options: [
                'All Risks of Physical Loss or Damage',
                'All Risks',
                'Difference in Conditions',
                'Named Perils',
                'Only',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'including',
              type: 'multiselect',
              label: 'Including',
              readonly: 'false',
              hideSelector: false,
              bullets: true,
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'excluding',
              type: 'multiselect',
              label: 'Excluding',
              hideSelector: false,
              bullets: true,
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'only',
              type: 'select',
              label: 'Only',
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsurerParticipation',
              type: 'textarea',
              label: 'Reinsurer Bound Participation',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Participation Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'brokerageCommissionSub',
              type: 'input',
              label: 'Brokerage Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value between 55%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '10.00%',
            },
          ],
          [
            {
              id: 'basisOfAcceptance',
              type: 'textarea',
              label: 'Basis of Acceptance',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis of Acceptance Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              defaultValue: 'Lockton Re Master Certificate',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceConditions',
              type: 'multiselect',
              label: 'Reinsurance Conditions',
              value: '',
              readonly: 'false',
              hideSelector: false,
              options: [],
              endpoint: 'facreinsuranceconditions|reinsuranceConditions',
              validations: [],
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'additionalReinsuranceConditions',
              type: 'textarea',
              label: 'Additional Reinsurance Conditions',
              inputType: 'text',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'Terms and Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Terms and Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'premiumTypepro',
              type: 'textarea',
              label: 'Premium Type',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Claims Nofitication Required',
                },
              ],
              value: '',
              defaultValue: 'Net Premium',
              hiddenToggle: false,
              hiddenToggleValue: true,
            },
          ],
          [
            {
              id: 'paymentDue',
              type: 'textarea',
              label: 'Payment Due',
              readonly: 'false',
              inputType: 'text',
              validations: [],
              value: '',
              defaultValue:
                '60 days from the end of the month in which the policy is effective.',
            },
          ],
        ],
      },
      {
        name: 'certificate-1',
        type: 'casualty',
        fields: [
          [
            {
              id: 'reinsured',
              type: 'textarea',
              label: 'Reinsured',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'textarea',
              label: 'Reinsured Underwriter',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: true,
            },
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Date Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'coverageReinsured',
              type: 'select',
              label: 'Coverage Reinsured',
              readonly: 'false',
              options: [
                'Auto Liability',
                'General Liability',
                'Umbrella / Excess Liability',
                "Workers' Compensation",
                'Professional Liability',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Name Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'policyLimit',
              type: 'input',
              label: 'Company Policy Limit',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Limit Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'generalAgg',
              type: 'input',
              label: 'General Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'General Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
            {
              id: 'otherAgg',
              type: 'input',
              label: 'Products and Completed Operations Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message:
                    'Products and Completed Operations Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
          ],
          [
            {
              id: 'programStructure',
              type: 'select',
              label: 'Program Structure',
              readonly: 'false',
              options: [
                'Guaranteed Cost',
                'Self-Insured Retention',
                'Deductible',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Program Structure Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'retentionAmount',
              type: 'input',
              label: 'Retention Amount',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Retention Required',
                },
              ],
              value: '',
              showCurrency: true,
              defaultValue: '0',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'basisOfAcceptance',
              type: 'textarea',
              label: 'Basis of Acceptance',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis of Acceptance Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              readonly: 'false',
              options: [],
              validations: [],
              defaultValue: 'USD',
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'brokerageCommissionSub',
              type: 'input',
              label: 'Brokerage Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value between 55%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '10.00%',
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceConditions',
              type: 'multiselect',
              label: 'Reinsurance Conditions',
              value: '',
              readonly: 'false',
              hideSelector: false,
              options: [],
              endpoint: 'facreinsuranceconditions|reinsuranceConditions',
              validations: [],
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'additionalReinsuranceConditions',
              type: 'textarea',
              label: 'Additional Reinsurance Conditions',
              inputType: 'text',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'otherFacultative',
              type: 'textarea',
              label: 'Other Facultative',
              inputType: 'text',
              readonly: 'true',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'minimumAndDeposit',
              type: 'textarea',
              label: 'Minimum and Deposit',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Minimum and Deposit Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '100% Minimum & Deposit',
            },
          ],
          [
            {
              id: 'rateAdjustability',
              type: 'textarea',
              label: 'Rate Adjustability',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Rate Adjustability Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: 'Reinsurance Premium is Flat',
            },
          ],
          [
            {
              id: 'claimsNotification',
              type: 'textarea',
              label: 'Claims Notification',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Claims Nofitication Required',
                },
              ],
              value: '',
              defaultValue: 'As per General Conditions',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'premiumTypecas',
              type: 'textarea',
              label: 'Premium Type',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Claims Nofitication Required',
                },
              ],
              value: '',
              defaultValue: 'Gross Premium',
              hiddenToggle: false,
              hiddenToggleValue: true,
            },
          ],
          [
            {
              id: 'reinsurerParticipation',
              type: 'textarea',
              label: 'Reinsurer Bound Participation',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Participation Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              defaultValue: 'Lockton Re Master Certificate',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'General Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'General Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'paymentDue',
              type: 'textarea',
              label: 'Payment Due',
              readonly: 'false',
              inputType: 'text',
              validations: [],
              value: '',
              defaultValue:
                '60 days from the end of the month in which the policy is effective.',
            },
          ],
        ],
      },
      {
        name: 'qtc-1',
        type: 'property',
        fields: [
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'textarea',
              label: 'Reinsured Underwriter',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: true,
            },
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsured',
              type: 'textarea',
              label: 'Reinsured',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              readonly: 'false',
              options: [],
              validations: [],
              defaultValue: 'USD',
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Insured Street Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'typeOfInsurance',
              type: 'select',
              label: 'Perils Insured',
              readonly: 'false',
              options: [
                'All Risks of Physical Loss or Damage',
                'All Risks',
                'Difference in Conditions',
                'Named Perils',
                'Only',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'including',
              type: 'multiselect',
              label: 'Including',
              readonly: 'false',
              hideSelector: false,
              bullets: true,
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'excluding',
              type: 'multiselect',
              label: 'Excluding',
              hideSelector: false,
              bullets: true,
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'only',
              type: 'select',
              label: 'Only',
              readonly: 'false',
              options: [
                'All Other Wind',
                'Boiler & Machinery',
                'California Earthquake',
                'Earth Movement',
                'Earthquake',
                'Equipment Breakdown',
                'Flood',
                'Hail',
                'Named Storm',
                'Named Windstorm',
                'Storm Surge',
                'Terrorism',
                'Wind Driven Precipitation',
                'Wind',
                'Windstorm',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Perils Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyLimit',
              type: 'input',
              label: 'Company Policy Limit',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Limit Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
          ],
          [
            {
              id: 'companyRetentionNetOrTreaty',
              type: 'textarea',
              label: 'Company Retention Net and/or Treaty',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Company Retention Net and/or Treaty Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'brokerageCommissionSub',
              type: 'input',
              label: 'Brokerage Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value between 55%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '10.00%',
            },
          ],
          [
            {
              id: 'basisOfAcceptance',
              type: 'select',
              label: 'Basis of Acceptance',
              readonly: 'false',
              options: [],
              endpoint: 'facbasisofacceptance|basisOfAcceptance',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis of Acceptance Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'additionalReinsuranceConditions',
              type: 'textarea',
              label: 'Additional Reinsurance Conditions',
              inputType: 'text',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'General Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Terms and Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              defaultValue: 'Lockton Re Master Certificate',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
        ],
      },
      {
        name: 'qtc-1',
        type: 'casualty',
        fields: [
          [
            {
              id: 'reinsured',
              type: 'textarea',
              label: 'Ceding Company',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuredUnderwriter',
              type: 'textarea',
              label: 'Reinsured Underwriter',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsured Underwriter Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: true,
            },
            {
              id: 'name',
              type: 'textarea',
              label: 'Named Insured',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Named Insured Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'streetAddress',
              type: 'textarea',
              label: 'Address',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Street Address Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cityName',
              type: 'textarea',
              label: 'City',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'City Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'stateName',
              type: 'select',
              label: 'State',
              readonly: 'false',
              options: [
                '',
                'AK',
                'AL',
                'AR',
                'AS',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'GU',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MP',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UM',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'State Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'zipCode',
              type: 'textarea',
              label: 'Zip Code',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Zip Code Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'policyPeriodFrom',
              type: 'date',
              label: 'Policy Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'policyPeriodTo',
              type: 'date',
              label: 'Policy Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'reinsurancePeriodFrom',
              type: 'date',
              label: 'Reinsurance Inception',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Inception Required',
                },
              ],
              value: '',
            },
            {
              id: 'reinsurancePeriodTo',
              type: 'date',
              label: 'Reinsurance Expiration',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Expiration Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'coverageReinsured',
              type: 'select',
              label: 'Coverage Reinsured',
              readonly: 'false',
              options: [
                'Auto Liability',
                'General Liability',
                'Umbrella / Excess Liability',
                "Workers' Compensation",
                'Professional Liability',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Name Required',
                },
              ],
              value: '',
            },
          ],
          [
            {
              id: 'currency',
              type: 'select',
              label: 'Currency',
              value: '',
              readonly: 'false',
              options: [],
              validations: [],
              defaultValue: 'USD',
              endpoint: 'faccurrency|currency',
            },
          ],
          [
            {
              id: 'policyLimit',
              type: 'input',
              label: 'Company Policy Limit',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Policy Limit Value Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              showCurrency: true,
            },
            {
              id: 'generalAgg',
              type: 'input',
              label: 'General Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'General Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
            {
              id: 'otherAgg',
              type: 'input',
              label: 'Products and Completed Operations Aggregate',
              inputType: 'number',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message:
                    'Products and Completed Operations Aggregate Value Required',
                },
              ],
              value: '',
              hiddenToggle: false,
              hiddenToggleValue: true,
              showCurrency: true,
            },
          ],
          [
            {
              id: 'programStructure',
              type: 'select',
              label: 'Program Structure',
              readonly: 'false',
              options: [
                'Guaranteed Cost',
                'Self-Insured Retention',
                'Deductible',
              ],
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Program Structure Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
            {
              id: 'retentionAmount',
              type: 'input',
              label: 'Retention Amount',
              inputType: 'currency',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Retention Required',
                },
              ],
              value: '',
              showCurrency: true,
              defaultValue: '0',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'cedingCommission',
              type: 'input',
              label: 'Ceding Commission',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'minimum',
              type: 'input',
              label: 'Minimum',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Minimum Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '100.00%',
            },
            {
              id: 'deposit',
              type: 'input',
              label: 'Deposit',
              inputType: 'percentage',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Deposit Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: '100.00%',
            },
          ],
          [
            {
              id: 'rateAdjustability',
              type: 'textarea',
              label: 'Rate Adjustability',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Rate Adjustability Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: 'Reinsurance Premium is Flat',
            },
          ],
          [
            {
              id: 'reinsuranceCoverage',
              type: 'textarea',
              label: 'Reinsurance Coverage',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Coveragae Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              defaultValue: 'Follow Form Company Policy',
            },
          ],
          [
            {
              id: 'basisOfAcceptance',
              type: 'select',
              label: 'Basis of Acceptance',
              readonly: 'false',
              options: [],
              endpoint: 'facbasisofacceptance|basisOfAcceptance',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Basis of Acceptance Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'allocatedLossAdjustmentExpense',
              type: 'select',
              label: 'ALAE',
              options: [
                'Outside Limit / Outside Certificate',
                'Inside Limit / Inside Certificate',
                'Capped',
                'Excluding Defense',
              ],
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'ALAE Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'reinsuranceCertificate',
              type: 'textarea',
              label: 'Reinsurance Certificate',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Reinsurance Certificate Required',
                },
              ],
              value: '',
              defaultValue: 'Lockton Re Master Certificate',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'termsAndConditions',
              type: 'select',
              label: 'General Conditions',
              readonly: 'false',
              options: [],
              endpoint: 'factermsandconditions|termsAndConditions',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Terms and Conditions Required',
                },
              ],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
            },
          ],
          [
            {
              id: 'additionalReinsuranceConditions',
              type: 'textarea',
              label: 'Additional Reinsurance Conditions',
              inputType: 'text',
              readonly: 'false',
              validations: [],
              value: '',
              hiddenToggle: true,
              hiddenToggleValue: false,
              bullets: true,
            },
          ],
        ],
      },
      {
        name: 'responses',
        fields: [
          [
            {
              id: 'marketResponses',
              type: 'input',
              label: 'Market Responses',
              inputType: 'text',
              readonly: 'false',
              validations: [
                {
                  name: 'required',
                  validator: 'required',
                  message: 'Please enter value less than or equal to 20%',
                },
              ],
              value: '',
              defaultValue: '[]',
              hiddenToggle: false,
              hiddenToggleValue: true,
            },
          ],
        ],
      },
    ],
  },
}

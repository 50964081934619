import { Action, createReducer, on } from '@ngrx/store'
import { AuthState, initialState } from '../state/auth.state'
import * as AuthActions from '../actions/auth.actions'

const authReducer = createReducer(
    initialState,
    on(
        AuthActions.IdentifySuccess,
        (state, { token, username, redirectURL, name }) => {
          return {
            ...state,
            username,
            name,
            token,
            authenticated: true,
            redirectURL,
          }
        }
    ),

)

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action)
}

import { commonEnvironment } from './environment-common'

const base = {
  timeout: 864000,
  api: {
    base: '/analyzere',
  },
}

export const environment = { ...base, ...commonEnvironment}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'

import { ChatPage } from './chat.page'
import { ChatPageRoutingModule } from './chat-routing.module'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { SwiperDirective } from "../services/swiper.directive";
import { ImageModalComponent } from './image-modal.ts/image-modal.component'
import { MarkdownComponent, MarkdownModule } from 'ngx-markdown'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChatPageRoutingModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    SwiperDirective,
    MarkdownModule.forChild()
  ],
  declarations: [
    ChatPage,
    ImageModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChatPageModule {}

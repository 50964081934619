import { ActionReducerMap } from '@ngrx/store'

import * as fromAuthState from '../state/auth.state'
import * as fromMenuState from '../state/menu.state'

import * as fromAuth from './auth.reducer'
import * as fromMenu from './menu.reducer'


export interface AppState {
    auth: fromAuthState.AuthState
    menu: fromMenuState.MenuState
}

export const reducers: ActionReducerMap<AppState> = {
    auth: fromAuth.reducer,
    menu: fromMenu.reducer
}

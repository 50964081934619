import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import html2canvas from 'html2canvas'

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {
  @Input() imageUrl: string
  @Input() chatText: string

  scale = 1
  startDistance = 0
  startX = 0
  startY = 0
  lastX = 0
  lastY = 0
  isPanning = false

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.initializePinchZoom()
  }

  closeModal(): void {
    this.modalController.dismiss()
  }

  resetImage(): void {
    const imageElement = document.getElementById('pinch-zoom-image') as HTMLElement
    this.scale = 1
    this.lastX = 0
    this.lastY = 0
    imageElement.style.transform = 'scale(1) translate(0px, 0px)'
  }

  async downloadImage(): Promise<void> {
    const container = document.getElementById('pinch-zoom-image') as HTMLElement
    const canvas = await html2canvas(container)
    const title = this.chatText.replace('Here is the ', '').replace('Here are the ', '')
    const dataUrl = canvas.toDataURL(`image/png`)
    const link = document.createElement('a')
    link.href = dataUrl
    link.download = `${title.charAt(0).toUpperCase() + title.slice(1)}.png`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  initializePinchZoom(): void {
    const imageElement = document.getElementById('pinch-zoom-image') as HTMLElement

    imageElement.addEventListener('touchstart', this.touchStart.bind(this), false)
    imageElement.addEventListener('touchmove', this.touchMove.bind(this), false)
    imageElement.addEventListener('touchend', this.touchEnd.bind(this), false)
  }

  getDistance(event: TouchEvent): number {
    const dx = event.touches[0].clientX - event.touches[1].clientX
    const dy = event.touches[0].clientY - event.touches[1].clientY
    return Math.sqrt(dx * dx + dy * dy)
  }

  calculateBoundaries(scale: number, containerWidth: number, containerHeight: number, imageWidth: number, imageHeight: number) {
    const scaledWidth = imageWidth * scale
    const scaledHeight = imageHeight * scale
    const boundaryX = Math.max(0, (scaledWidth - containerWidth) / (2 * scale))
    const boundaryY = Math.max(0, (scaledHeight - containerHeight) / (2 * scale))
    return { boundaryX, boundaryY }
  }

  touchStart(event: TouchEvent): void {
    if (event.touches.length === 2) {
      event.preventDefault()
      this.startDistance = this.getDistance(event)
      this.isPanning = false
    } else if (event.touches.length === 1) {
      this.startX = event.touches[0].clientX - this.lastX
      this.startY = event.touches[0].clientY - this.lastY
      this.isPanning = true
    }
  }

  touchMove(event: TouchEvent): void {
    const imageElement = document.getElementById('pinch-zoom-image') as HTMLElement
    const containerElement = document.getElementById('image-container') as HTMLElement

    if (event.touches.length === 2) {
      event.preventDefault()
      const currentDistance = this.getDistance(event)
      let newScale = (currentDistance / this.startDistance) * this.scale

      newScale = Math.max(1, Math.min(newScale, 3))

      const { boundaryX, boundaryY } = this.calculateBoundaries(newScale, containerElement.offsetWidth, containerElement.offsetHeight, imageElement.offsetWidth, imageElement.offsetHeight)

      this.lastX = Math.max(Math.min(this.lastX, boundaryX), -boundaryX)
      this.lastY = Math.max(Math.min(this.lastY, boundaryY), -boundaryY)

      imageElement.style.transform = `scale(${newScale}) translate(${this.lastX}px, ${this.lastY}px)`
    } else if (event.touches.length === 1 && this.isPanning) {
      event.preventDefault()
      const moveX = (event.touches[0].clientX - this.startX) * 0.96
      const moveY = (event.touches[0].clientY - this.startY) * 0.96

      const { boundaryX, boundaryY } = this.calculateBoundaries(this.scale, containerElement.offsetWidth, containerElement.offsetHeight, imageElement.offsetWidth, imageElement.offsetHeight)

      this.lastX = Math.max(Math.min(moveX, boundaryX), -boundaryX)
      this.lastY = Math.max(Math.min(moveY, boundaryY), -boundaryY)

      imageElement.style.transform = `scale(${this.scale}) translate(${this.lastX}px, ${this.lastY}px)`
    }
  }

  touchEnd(): void {
    this.isPanning = false
    const imageElement = document.getElementById('pinch-zoom-image') as HTMLElement
    this.scale = Number((imageElement.style.transform.match(/scale\(([^)]+)\)/)?.[1]) || this.scale)
  }
}

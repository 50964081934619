import { Action, createReducer, on } from '@ngrx/store'
import { MenuState, initialState } from '../state/menu.state'
import { updateShowMenu } from '../actions/menu.actions'

const menuReducer = createReducer(
    initialState,
    on(
        updateShowMenu,
        (state, { showMenu }) => {
          return {
            ...state,
            showMenu
          }
        }
    ),
)

export function reducer(state: MenuState | undefined, action: Action): MenuState {
  return menuReducer(state, action)
}

import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'

import { MaterialModule } from './material.module'
import { LoadingPopupComponent } from './loading-popup/loading-popup.component'

const MODULES = [
  CommonModule,
  FormsModule,
  MaterialModule,
  ScrollingModule,
]

@NgModule({
  declarations: [LoadingPopupComponent],
  exports: [...MODULES, LoadingPopupComponent],
  imports: MODULES,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})

export class SharedModule {}

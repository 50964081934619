<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="resetImage()">Reset Image</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="downloadImage()">
        <ion-icon name="download-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="closeModal()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="image-container" class="image-container">
    <img id="pinch-zoom-image" [src]="imageUrl" />
  </div>/>
</ion-content>

import { UserPermissions } from "../models/auth.model";
import {
    BlobResponse,
    CarrierResponse,
    FeatureFlag,
    SharedLimit,
    SharedLimitMember,
    StudyResponse
} from "../../api/model/backend.model";
import { CompareView } from "../models/compare-view.model";

export interface AuthState {
    username: string | null
    name: string | null
    authenticated: boolean
    redirectURL: string | null
    analyzeReToken: string | null
    marketToken: string | null
    userPermissions: UserPermissions | null
    security: string[] | null
    blobs: {
        thumbnail?: BlobResponse
        carrier?: BlobResponse
        reinsurer?: BlobResponse
        market?: BlobResponse
    }
    carriers: CarrierResponse[] | null
    studies: StudyResponse[] | null
    userPermissionsError: string | null
    token: string | null
    selectedApp: string | null
    authenticatingUser: string | null
    sageLogicalRole: string | null
    featureFlags: FeatureFlag[] | null
    sharedLimits: SharedLimit[] | null
    sharedLimitMembers: SharedLimitMember[] | null
    compareViews: CompareView[] | null
}

export const initialState: AuthState = {
    username: null,
    name: null,
    authenticated: true,
    redirectURL: null,
    analyzeReToken: null,
    marketToken: null,
    userPermissions: null,
    security: null,
    blobs: {},
    carriers: null,
    studies: null,
    userPermissionsError: null,
    token: null,
    selectedApp: null,
    authenticatingUser: null,
    sageLogicalRole: null,
    featureFlags: null,
    sharedLimits: null,
    sharedLimitMembers: null,
    compareViews: null,
}

import {
  HttpErrorResponse,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http'
// import { splitEvery } from 'ramda'
import {
  concat,
  forkJoin,
  Observable,
  ObservableInput,
  of,
  OperatorFunction,
  pipe,
  UnaryFunction,
} from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  takeLast,
  takeWhile,
  withLatestFrom,
} from 'rxjs/operators'
import { ApplicationError, errorPayload } from './model/error.model'
import { ApiResponse, HasData, MaybeData, MaybeError } from './model/api.model'

export const mapToData = <T>(): OperatorFunction<T, MaybeData<T>> =>
  map((res: T) => ({ data: res }))

export const catchAndHandleError = <T, R extends object>(
  op?: string,
  result?: R
): OperatorFunction<T, T | (R & MaybeError)> =>
  catchError(handleError(op, result))

export function handleError<T extends object>(op = 'API', result?: T) {
  return (err: unknown): Observable<T & MaybeError> => {
    let message = `${op} failed.`
    if ((err as any)?.status === 403) {
      message = 'The user has no access to this Entity'
    }
    const details = getDetailsMessage(err)
    const text = getTextMessage(err)
    console.error(message, err)
    const error = errorPayload(message, details, { text })
    return of(Object.assign({}, { error }, result) as MaybeError & T)
  }
}

const getDetailsMessage = (e: unknown): string[] => {
  if (e instanceof HttpErrorResponse) {
    const messages = [e.message]
    const msg = e?.error?.message ?? e?.error?.Message
    if (msg) {
      messages.push(msg)
    }
    return messages
  } else {
    if (e instanceof Error) {
      const messages = [e.message]
      return messages
    }
  }
  return []
}

const getTextMessage = (e: unknown): string => {
  if (e instanceof HttpErrorResponse) {
    return e?.error?.text ?? ''
  } else if (e instanceof Error) {
    return e.message
  }
  return ''
}

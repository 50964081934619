import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import {
  B2CConfig, UserInfo,
//   CreateFacUserRelationship,
//   FacBasisOfAcceptance,
//   FacCurrency,
//   FacNamedInsured,
//   FacReinsuranceConditions,
//   FacTermsAndConditions,
//   FacTypeOfInsurance,
//   FacUserRelationshipResponse,
//   FacUserType,
//   FacUsersListResponse,
//   OTCreateNewQuoteRequest,
//   OTRiskConfirmRequest,
//   OTRiskConfirmResponse,
//   OTRiskRequest,
//   OTRiskResponse,
//   PostEmailElement,
//   ProcessLog,
//   RiskSectionWithMarkets,
//   UserInfo,
} from './model/backend.model'
import { catchError, map, Observable, throwError } from "rxjs";
import { ApiResponse } from "./model/api.model";
// import { Observable, of } from 'rxjs'
// import { ApiResponse, MaybeError } from '../model/api.model'
// import {
//   AnalyzeReAuthResponse,
//   PermissionsResponse,
//   MarketTokenResponse,
//   LogEvent,
//   User,
//   UserRole,
//   Role,
//   SageFunctionality,
//   NewUser,
//   NewUserRole,
//   FacPropertySubmission,
//   FacBinder,
//   FacMailingGroups,
//   PostFacDataElement,
//   FacReinsurer,
// } from '../model/backend.model'
import { catchAndHandleError, mapToData, handleError } from './api.util'
// import { LayerData } from 'src/app/analysis/store/analysis.actions'
// import {
//   FacData,
//   FacDataSearchCriteria,
//   FacDataVersion,
//   FacultativeResponse,
//   SalesforceAccount,
//   SalesforceContact,
// } from 'src/app/facultative/facultative.model'
// import { md5 } from '@shared/util/hash'
// import { catchError } from 'rxjs/operators'
// import { errorPayload } from 'src/app/error/model/error'
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(private httpClient: HttpClient) {}

  getAzureB2CConfig(
    email: string,
    passwordReset: boolean = false
  ): any {
    const url = passwordReset
        ? `${environment.internalApi.base}${environment.internalApi.azureb2c}?passwordReset=true`
        : email
            ? `${environment.internalApi.base}${environment.internalApi.azureb2c}?email=${email}`
            : `${environment.internalApi.base}${environment.internalApi.azureb2c}`
    console.log('%c API url 2 ', 'background:darkorange; color: white', url, environment.internalApi);
    return this.httpClient
      .get<B2CConfig>(url)
      .pipe(
        mapToData(), catchAndHandleError('GET B2C Config'))
        // map(rsp => {
        //   console.log('%c API getAzureB2CConfig ', 'background: #260000FF; color: white;', url, rsp);
        //   return rsp;
        // }),
        // catchError(this.handleError))
  }

  protected handleError(error: HttpErrorResponse) {
    console.error('Error encountered:', error);
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  putUserTimeStamp(id: number, userRequest: string) {
    const url = `${environment.internalApi.base}${environment.internalApi.users}/${id}/approval_timestamp`
    return this.httpClient
      .put(url, userRequest, httpOptions)
      .pipe(mapToData(), catchAndHandleError('PUT User TimeStamp'))
  }

}

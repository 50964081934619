import { createAction, props } from '@ngrx/store'
import { Auth } from '../models/auth.model'

export enum AuthActions {
    IdentifySuccess = '[Auth] Identify Success',
}

export const IdentifySuccess = createAction(
    AuthActions.IdentifySuccess,
  props<Auth>()
)

// export const UpdateAuthToken = createAction(
//   '[Auth] Update Token',
//   props<{ token: string }>()
// )

// export const IdentifyPermissionsSuccess = createAction(
//   '[Auth] Identify Permissions Success',
//   props<EntitiesResponse>()
// )

// export const IdentifyPermissionsFailure = createAction(
//   '[Auth] Identify Permissions Failure',
//   props<{ error: any }>()
// )

// export const SetAuthenticatingUser = createAction(
//   '[Auth] Set Authenticating User',
//   props<{ authenticatingUser: string }>()
// )

// export const SetSageLogicalRole = createAction(
//   '[Auth] Set Sage Logical Role',
//   props<{ sageLogicalRole: string }>()
// )

// export const AuthenticateSuccess = createAction(
//   '[Auth] Authenticate User Success'
// )
// export const AuthenticateFailure = createAction(
//   '[Auth] Authenticate User Failure'
// )

// export const SetAnalyzereToken = createAction(
//   '[Auth] Set AnalyzeRe Token',
//   props<{ token: string }>()
// )
// export const SetAnalyzereTokenFailure = createAction(
//   '[Auth] Set AnalyzeRe Token Failure',
//   props<{ error: any }>()
// )

// export const SetMarketToken = createAction(
//   '[Auth] Set Market Token',
//   props<{ marketToken: string }>()
// )

// export const SetMarketTokenSuccess = createAction(
//   '[Auth] Set Market Token Success',
//   props<{ token: string }>()
// )

// export const SetMarketTokenFailure = createAction(
//   '[Auth] Set Market Token Failure',
//   props<{ error: any }>()
// )

// export const SetSelectedApp = createAction(
//   '[Auth] Set Selected App',
//   props<{ selectedApp: string }>()
// )

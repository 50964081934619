import { AppState } from '../reducers'
import { createSelector } from '@ngrx/store'

export const selectAuthState = (state: AppState) => state.auth

export const selectToken = createSelector(
  selectAuthState,
  auth => auth.token
)

export const selectUsername = createSelector(
  selectAuthState,
  auth => auth.username
)

export const selectReinsurerBlob = createSelector(
  selectAuthState,
  state => state.blobs.reinsurer || null
)

export const selectSharedLimits = createSelector(
  selectAuthState,
  state => state.sharedLimits || []
)

export const selectSharedLimitMembers = createSelector(
  selectAuthState,
  state => state.sharedLimitMembers || []
)

<ion-app>
  <ion-menu-button *ngIf="showHamburger" class="hamburger" (click)="hamburgerClick()"></ion-menu-button>
  <ion-menu *ngIf="showMenu" contentId="main-content" type="overlay">
    <ion-content>
      <ion-list id="inbox-list">
        <ion-list-header>
          <img src="assets/logos/sage-logo.png" class="sage-logo" alt="SAGE" />
        </ion-list-header>
        <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
          <ion-item
            routerDirection="root"
            lines="none" detail="false" routerLinkActive="selected"
            (click)="setChatType(p.title)"
            [routerLink]="[p.url]"
          >
            <ion-icon
              aria-hidden="true" slot="start"
              [ios]="p.icon + '-outline'"
              [md]="p.icon + '-sharp'"
            ></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
